import React, { FC, useState, useEffect } from 'react';
import { DataGrid, GridActionsCellItem, GridRowId, GridColDef, GridToolbarContainer, GridFooterContainer, GridSortModel } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { SearchOutlined } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from '@mui/icons-material/Warning';
import EditIcon from '@mui/icons-material/Edit';
import CatchRegistration from '../../../../Models/CatchRegistrationModels/CatchRegistration';
import { CatchRegistrationDataGridWrapper, NoDataFound, NoDataFoundIcon } from './CatchRegistrationDataGrid.styled';
import { deleteWeight } from '../../../../Services/LondunarkerfiAPIService';
import { Button, Chip, Tooltip } from '@mui/material';
import CatchRegistrationModal from '../CatchRegistrationModal/CatchRegistrationModal';
import User from '../../../../Models/UserModels/User';
import { useConfirm } from "material-ui-confirm";
import Vehicle from '../../../../Models/CatchRegistrationModels/Vehicle';
import Landing from '../../../../Models/LandingModels/Landing';
import WeightNote from '../../../../Models/WeightNoteModels/WeightNote';
import CatchCombination from '../../../../Models/CatchRegistrationModels/CatchCombination';
import FishingArea from '../../../../Models/CatchRegistrationModels/FishingArea';
import StorageMethod from '../../../../Models/CatchRegistrationModels/StorageMethod';
import Destiny from '../../../../Models/CatchRegistrationModels/Destiny';
import FishingStock from '../../../../Models/CatchRegistrationModels/FishingStock';
import Condition from '../../../../Models/CatchRegistrationModels/Condition';
import ReWeightingModal from '../../../ReWeighting/Components/ReWeightingModal/ReweightingModal';
import { OverviewCardTitle } from '../../Home.styled';
import Gear from '../../../../Models/WeightNoteModels/Gear';
import DeductionType from '../../../../Models/CatchRegistrationModels/DeductionType';
import WeightNoteTypes from '../../../../Constants/WeightNoteTypes';
import { logError } from '../../../../Helpers/LogError';
import PostCatchBody from '../../../../Models/CatchRegistrationModels/PostCatchBody';

interface CatchRegistrationDataGridProps {
  allowedCombinations: CatchCombination[] | null;
  fishAreas: FishingArea[];
  storageMethods: StorageMethod[];
  destinies: Destiny[];
  fishingStocks: FishingStock[];
  conditions: Condition[];
  vehicles: Vehicle[];
  gears: Gear[];
  deductionTypes: DeductionType[];
  showSnackbar: (message: string, severity: string) => void;
  fetchWeightNotes: (landing: Landing, weightNoteId: number) => Promise<WeightNote[]>;
  selectedLanding: Landing;
  selectedWeightNote: WeightNote;
  user: User;
  loading: boolean;
  isMobileView: boolean;
}

/**
 * Functional component for CatchRegistrationDataGrid.
 * Displays a dataGrid with all catch assigned to a specific weightNote.
 * @param props 
 * - takes in various values used for dropDown values.
 * - takes in the selected landing and the selected weightNote.
 * - takes in a user object.
 * @returns {JSX} - Responsible for returning the datagrid with all catch assigned to a specific weightNote.
 * Should render the following components:
 * - CatchRegistrationModal
 */

const CatchRegistrationDataGrid: FC<CatchRegistrationDataGridProps> = (props) => {
  const [weightItem, setWeightItem] = useState<CatchRegistration | undefined>();
  const [catchModalOpen, setCatchModalOpen] = useState(false);
  const [reWeightingModalOpen, setReWeightingModalOpen] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortModel, setSortModel] = useState<GridSortModel>();

  const F7_KEY_CODE = 118;
  const confirm = useConfirm();
  const sortedWeights = props.selectedWeightNote?.weights?.slice().sort((a, b) => a.id - b.id);

  useEffect(() => {
    // Initiate sort model.
    initiateSortModel();

    // Keyboard shortcuts.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const keyboardNewCatch = (e: any) => {
      const allModalsClosed = document.body.getElementsByClassName("MuiDialog-root")?.length === 0;
      if ((e.keyCode) === F7_KEY_CODE) {
        e.preventDefault();
        if (allModalsClosed) {
          handleNewCatch();
        }
      }
    };
    window.addEventListener('keydown', keyboardNewCatch);
    return () => {
      window.removeEventListener('keydown', keyboardNewCatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiateSortModel = () => {
    if (!sortModel) {
      setSortModel([{ field: 'fishingStock', sort: 'asc' }]);
    }
  };

  const toggleCatchModalOpen = () => {
    setCatchModalOpen(!catchModalOpen);
  }

  const setCatchModalToOpen = () => {
    setCatchModalOpen(true);
  }

  const toggleReWeightingModalOpen = () => {
    setReWeightingModalOpen(!reWeightingModalOpen);
  }

  const setReWeightingModalToOpen = () => {
    setReWeightingModalOpen(true);
  }

  const NoRowsFound = () => {
    let txt = 'Engin aflaskráning fannst fyrir valda vigtarnótu'
    if (!props.selectedWeightNote.id) {
      txt = 'Engin vigtarnóta er valin';
    }
    return (
      <NoDataFound>
        <NoDataFoundIcon>
          <SearchOutlined />
        </NoDataFoundIcon>
        {txt}
      </NoDataFound>
    );
  };

  const columns = React.useMemo<GridColDef<CatchRegistration>[]>(() => [
    { field: 'id', headerName: 'ID', headerAlign: 'left' },
    { field: 'fishingStock', minWidth: props.isMobileView ? 110 : undefined, headerName: 'Veiðistofn', type: 'singleSelect', headerAlign: 'left', flex: 1, valueGetter: (params) => params.row.fishingStock.name },
    { field: 'condition', minWidth: props.isMobileView ? 110 : undefined, headerName: 'Ástand', type: 'singleSelect', headerAlign: 'left', flex: 1, valueGetter: (params) => params.row?.condition.name },
    { field: 'destiny', minWidth: props.isMobileView ? 110 : undefined, headerName: 'Afdrif', type: 'singleSelect', headerAlign: 'left', flex: 1, valueGetter: (params) => params.row?.destiny.name },
    {
      field: props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID ? 'sampleWeight' : 'weight',
      minWidth: props.isMobileView ? 90 : undefined,
      headerName: props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID ? 'Vigtun úrtaks' : 'Vigt',
      type: 'number',
      headerAlign: 'left',
      flex: 1
    },
    { field: 'icePercentage', minWidth: props.isMobileView ? 90 : undefined, headerName: 'Ís (%)', type: 'number', headerAlign: 'left', flex: 1 },
    { field: 'underKilogram', minWidth: props.isMobileView ? 90 : undefined, headerName: 'Undirmál', type: 'boolean', headerAlign: 'left', flex: 1 },
    { field: 'carRegistrationNumber', minWidth: props.isMobileView ? 110 : undefined, headerName: 'Flutningstæki', type: 'string', headerAlign: 'left', flex: 1, valueGetter: (params) => params.row?.vehicle?.vehicleNumber },
    { field: 'carDeduction', minWidth: props.isMobileView ? 110 : undefined, headerName: 'Frádráttur flutningstækis', headerAlign: 'left', flex: 1, type: 'number' },
    { field: 'deductions', minWidth: props.isMobileView ? 110 : undefined, headerName: 'Frádráttur', type: 'number', headerAlign: 'left', flex: 1, valueGetter: (params) => params.row.deductions?.reduce((total, deduction) => total + deduction.quantity, 0) },
    { field: 'quantity', minWidth: props.isMobileView ? 110 : undefined, headerName: 'Magn', type: 'number', headerAlign: 'left', flex: 1 },
    {
      field: 'warningInfo',
      type: 'actions',
      flex: 0.5,
      getActions: (params) => {
        const sumOfDeductions = params.row.deductions?.reduce((total, deduction) => total + deduction.quantity, 0);
        if ((props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_SAMPLE_ID || props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID) && sumOfDeductions === 0) {
          return [
            <Tooltip title='Vantar frádrátt' arrow>
              <GridActionsCellItem
                icon={<WarningIcon />}
                label="missing-data"
                color="warning"
                onClick={() => handleClick(params)}
              />
            </Tooltip>,
          ];
        }
        if (props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID && params.row.weight === 0) {
          return [
            <Tooltip title='Vantar að uppreikna vigtun' arrow>
              <GridActionsCellItem
                icon={<WarningIcon />}
                label="missing-data"
                color="warning"
                onClick={() => handleClick(params)}
              />
            </Tooltip>,
          ];
        }
        if (!props.selectedWeightNote.isClosed) {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="edit"
              color="primary"
              onClick={() => handleClick(params)}
            />
          ];
        }
        return [];
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 0.5,
      getActions: (params) => {
        if (props.selectedWeightNote.isClosed) {
          return [
            <GridActionsCellItem
              icon={<SearchIcon />}
              label="preview"
              color="primary"
              onClick={handlePreview}
            />
          ];
        }
        if (props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)) {
          return [
            <GridActionsCellItem
              icon={<SearchIcon />}
              label="preview"
              color="primary"
              onClick={handlePreview}
            />
          ];
        }
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            onClick={handleDelete(params.id)}
          />
        ];
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [props.selectedWeightNote]);

  async function delWeight(weightId: number) {
    try {
      setLoading(true);
      await deleteWeight(weightId);
      props.fetchWeightNotes(props.selectedLanding, props.selectedWeightNote.id);
      props.showSnackbar('Vigtun eytt!', 'success');
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const handleDelete = (weightId: GridRowId) => () => {
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun eyða vigtun nr: ${weightId}.`,
      confirmationText: 'Eyða',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'error' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => delWeight(weightId as number))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (params: any) => {
    setWeightItem(params.row);
    setCarouselIndex(sortedWeights?.findIndex(w => w.id === params.row.id) ?? undefined);
    if (props.selectedWeightNote.weightNoteType.id === WeightNoteTypes.FROM_REWEIGHTER) {
      setReWeightingModalToOpen();
      return;
    }
    setCatchModalToOpen();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePreview = (params: any) => {
    toggleCatchModalOpen();
    setWeightItem(params.row);
  };

  const resetWeightItem = () => {
    setWeightItem(undefined);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid rgba(224, 224, 224, 1)', padding: 0 }}>
        <OverviewCardTitle style={{ paddingTop: '0' }}>
          Aflaskráning
        </OverviewCardTitle>
        <Button
          aria-label='new-catch'
          color='primary'
          size='medium'
          variant='contained'
          sx={{ display: 'flex', marginTop: '0.75em', marginBottom: '0.75em', marginRight: '1em' }}
          onClick={handleNewCatch}
          disabled={props.selectedWeightNote.isClosed || loading || props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)}
        >
          <AddIcon />
          <Tooltip title='F7' arrow>
            <span>Ný aflaskráning</span>
          </Tooltip>
        </Button>
      </GridToolbarContainer>
    );
  }

  const sumQuantity: number | undefined = props.selectedWeightNote.weights?.reduce((acc, row) => acc + row.quantity, 0);
  const quantitySum = new Intl.NumberFormat('de-DE', { style: 'decimal', maximumFractionDigits: 0, }).format(sumQuantity ?? 0);

  const sumDeduction: number | undefined = props.selectedWeightNote.weights?.reduce((acc, row) => acc + row.deduction, 0);
  const deductionSum = new Intl.NumberFormat('de-DE', { style: 'decimal', maximumFractionDigits: 0, }).format(sumDeduction ?? 0);

  const sumWeight: number | undefined = props.selectedWeightNote.weights?.reduce((acc, row) => acc + (props.selectedWeightNote?.weightNoteType.id === WeightNoteTypes.PRODUCT_ID ? row.sampleWeight : row.weight), 0);
  const weightSum = new Intl.NumberFormat('de-DE', { style: 'decimal', maximumFractionDigits: 0, }).format(sumWeight ?? 0);

  const sumVehicleDeduction: number | undefined = props.selectedWeightNote.weights?.reduce((acc, row) => acc + row.carDeduction, 0);
  const vehicleDeductionSum = new Intl.NumberFormat('de-DE', { style: 'decimal', maximumFractionDigits: 0, }).format(sumVehicleDeduction ?? 0);

  function CustomFooter() {
    return (
      <GridFooterContainer sx={{ display: 'flex', width: '100%', borderTop: '1px solid #e0e0e0', padding: 0 }}>
        {props.isMobileView &&
          <div style={{ overflowY: 'auto', display: 'flex', flexDirection: 'row', gap: '0.5em', padding: '0.25em' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Chip style={{ fontWeight: 'bold', fontSize: '0.95em' }} variant="outlined" label={props.isMobileView && 'Vigt ' + weightSum + ' kg'}></Chip>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Chip style={{ fontWeight: 'bold', fontSize: '0.95em' }} variant="outlined" label={props.isMobileView && 'Magn ' + quantitySum + ' kg'}></Chip>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Chip style={{ fontWeight: 'bold', fontSize: '0.95em' }} variant="outlined" label={props.isMobileView && 'Frádr. flutningstækis ' + vehicleDeductionSum + ' kg'}></Chip>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Chip style={{ fontWeight: 'bold', fontSize: '0.95em' }} variant="outlined" label={props.isMobileView && 'Frádráttur ' + deductionSum + ' kg'}></Chip>
            </div>
          </div>
        }
        {!props.isMobileView && (
          columns.slice(1).map((column, index) => (
            <div
              key={index}
              style={{
                flex: column.flex || '1 1 auto',
                width: column.width || 'auto',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                alignItems: index === 0 ? 'left' : 'center',
                boxSizing: 'border-box',
              }}
            >
              {index === 0 && (
                <b style={{ fontSize: '1em', paddingLeft: '0.5em' }}>Samtals</b>
              )}
              {index === 3 && (
                <span style={{ fontSize: '1em' }}>{weightSum + ' kg'}</span>
              )}
              {index === 7 && (
                <span style={{ fontSize: '1em' }}>{vehicleDeductionSum + ' kg'}</span>
              )}
              {index === 8 && (
                <span style={{ fontSize: '1em' }}>{deductionSum + ' kg'}</span>
              )}
              {index === 9 && (
                <span style={{ fontSize: '1em' }}>{quantitySum + ' kg'}</span>
              )}
            </div>
          ))
        )}
      </GridFooterContainer>
    );
  }

  const handleNewCatch = () => {
    setWeightItem(undefined);
    setCarouselIndex(sortedWeights?.length);
    if (props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.FROM_REWEIGHTER) {
      toggleReWeightingModalOpen();
      return;
    }
    toggleCatchModalOpen();
  }

  const setNewWeightItem = async (weightId: number, formData?: PostCatchBody) => {
    const updatedWeightNote = await props.fetchWeightNotes(props.selectedLanding, props.selectedWeightNote.id);
    const weightNote = updatedWeightNote?.find(w => w.id === props.selectedWeightNote.id);
    let newWeightItem = weightNote?.weights?.find(w => w.id === weightId);
    if (newWeightItem) {
      if (formData) {
        newWeightItem = { ...newWeightItem, ...formData };
      }
      setWeightItem(newWeightItem);
    }
  }

  const handleCarouselChange = (index: number | undefined) => {
    setCarouselIndex(index);
    setWeightItem(sortedWeights?.[index ?? 0]);
  }

  return (
    <CatchRegistrationDataGridWrapper
      style={{ height: props.isMobileView ? 'calc(100% - 11.5em)' : '100%' }}
    >
      {(props?.selectedLanding.id && props.selectedWeightNote.id) && (
        <CatchRegistrationModal
          open={catchModalOpen}
          toggleCatchOpen={toggleCatchModalOpen}
          user={props.user}
          weightItem={weightItem}
          selectedCarouselIndex={carouselIndex}
          sortedWeights={props.selectedWeightNote.weights?.slice().sort((a, b) => a.id - b.id) ?? []}
          setIndex={handleCarouselChange}
          setNewWeightItem={setNewWeightItem}
          resetWeightItem={resetWeightItem}
          catchCombinations={props.allowedCombinations}
          fishAreas={props.fishAreas}
          storageMethods={props.storageMethods}
          destinies={props.destinies}
          fishingStocks={props.fishingStocks}
          conditions={props.conditions}
          vehicles={props.vehicles}
          gears={props.gears}
          deductionTypes={props.deductionTypes}
          showSnackbar={props.showSnackbar}
          selectedWeightNote={props.selectedWeightNote}
        />
      )}

      {(props?.selectedLanding.id && props.selectedWeightNote.id) && (
        <ReWeightingModal
          user={props.user}
          open={reWeightingModalOpen}
          selectedWeightNote={props.selectedWeightNote}
          selectedWeightItem={weightItem}
          fihsingStocks={props.fishingStocks}
          fishingArea={props.fishAreas}
          storageMethods={props.storageMethods}
          destinies={props.destinies}
          conditions={props.conditions}
          catchCombinations={props.allowedCombinations ? props.allowedCombinations : []}
          toggleOpen={toggleReWeightingModalOpen}
          showSnackbar={props.showSnackbar}
          resetWeightItem={resetWeightItem}
          fetchWeightNotes={props.fetchWeightNotes}
          selectedLanding={props.selectedLanding}
        />
      )}

      {(props?.selectedLanding.id && props.selectedWeightNote.id) && (
        <DataGrid
          disableVirtualization
          loading={loading || props.loading}
          columns={columns}
          rows={props.selectedWeightNote.weights ?? []}
          slots={{
            noRowsOverlay: NoRowsFound,
            footer: CustomFooter,
            toolbar: CustomToolbar
          }}
          sortModel={sortModel}
          onSortModelChange={(sortModel) => {
            setSortModel(sortModel);
          }}
          hideFooterPagination
          onRowClick={handleClick}
          columnVisibilityModel={{ id: false }}
          density='compact'
          disableColumnMenu
          showColumnVerticalBorder
          showCellVerticalBorder
          sx={{
            "&.MuiDataGrid-root": {
              borderLeft: 0,
              borderRight: 0,
              borderBottom: 0,
              borderRadius: 0,
            },
          }}
        />
      )}
    </CatchRegistrationDataGridWrapper>
  );
};

export default CatchRegistrationDataGrid;