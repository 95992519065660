import { FC, useState } from 'react';
import { LandingInfoWrapper, SplitContainer, LandingTitleFont, LandingTitleContainer } from './LandingInfo.styled';
import dayjs from 'dayjs';
import Landing from '../../../../Models/LandingModels/Landing';
import { deleteLanding, getLandingById, patchLanding } from '../../../../Services/LondunarkerfiAPIService';
import CommentIcon from '@mui/icons-material/Comment';
import ArticleIcon from '@mui/icons-material/Article';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TagIcon from '@mui/icons-material/Tag';
import EditIcon from '@mui/icons-material/Edit';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { useConfirm } from "material-ui-confirm";
import { Avatar, Button, Divider, IconButton, Tab, Tooltip, useTheme } from '@mui/material';
import TaskIcon from '@mui/icons-material/Task';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditLandingModal from '../EditLandingModal/EditLandingModal';
import LandingSubmit from '../../../../Models/LandingModels/LandingSubmit';
import LandingType from '../../../../Models/LandingModels/LandingType';
import Permit from '../../../../Models/ReWeightingModels/Permit';
import WeightNote from '../../../../Models/WeightNoteModels/WeightNote';
import User from '../../../../Models/UserModels/User';
import UndoIcon from '@mui/icons-material/Undo';
import CheckIcon from '@mui/icons-material/Check';
import CompareIcon from '@mui/icons-material/Compare';
import ErrorIcon from '@mui/icons-material/Error';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import WarningIcon from '@mui/icons-material/Warning';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import UserHarbours from '../../../../Models/UserModels/UserHarbours';
import AdminLandingModal from '../AdminLandingModal/AdminLandingModal';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import NewWeightNoteModal from '../NewWeightNoteModal/NewWeightNoteModal';
import WeightNoteType from '../../../../Models/WeightNoteModels/WeightNoteType';
import Gear from '../../../../Models/WeightNoteModels/Gear';
import CompareCatchModal from './components/CompareCatchModal';
import LandingStatuses from '../../../../Constants/LandingStatuses';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WeightNoteTypes from '../../../../Constants/WeightNoteTypes';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { logError } from '../../../../Helpers/LogError';

interface LandingInfoProps {
  loading: boolean;
  landingData: Landing[];
  selectedLanding: Landing;
  selectedWeightNote: WeightNote;
  landingTypes: LandingType[];
  fetchLandings: () => void;
  fetchCompleteLandings: (pageNumber: 1, refetching: boolean, requestedLandingId?: number) => void;
  setSelectedLanding: (landing: Landing, newLandingId?: number, landings?: Landing[], newWeightNoteId?: number) => void;
  showSnackbar: (message: string, severity: string) => void;
  permits: Permit[];
  user: User;
  harbours: UserHarbours[];

  newWeightNoteModalOpen: boolean;
  toggleNewWeightNoteModalOpen: () => void;
  fishingGears: Gear[];
  weightNoteTypes: WeightNoteType[];
  isMobileView: boolean;
  currentPage: string;
  setCurrentPage: (page: string) => void;
}

/**
 * Functional component for LandingInfo.
 * Displays the information of the selected landing.
 * @param props 
 * - takes in various values used for dropDown values.
 * - takes in the selected landing and the selected weightNote.
 * - takes in a function to fetch landings.
 * @returns {JSX} - Responsible for returning the landing info.
 * should render the following components:
 * - EditLandingModal
 */

const LandingInfo: FC<LandingInfoProps> = (props) => {

  const [editLandingModalOpen, setEditLandingModalOpen] = useState(false);
  const [adminLandingModalOpen, setAdminLandingModalOpen] = useState(false);
  const [compareCatchModalOpen, setCompareCatchModalOpen] = useState(false);
  const [inputToFocus, setInputToFocus] = useState<string | undefined>('');
  const theme = useTheme();
  const FIRST_PAGE = 1;

  // For mobile view three dot menu.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleEditLandingModalOpen = (inputToFocus?: string) => {
    setInputToFocus(inputToFocus);
    setEditLandingModalOpen(!editLandingModalOpen);
  }

  const toggleAdminLandingModalOpen = () => {
    setAdminLandingModalOpen(!adminLandingModalOpen);
  }

  const toggleCompareCatchModalOpen = () => {
    setCompareCatchModalOpen(!compareCatchModalOpen);
  }

  const confirm = useConfirm();
  const rollbackConfirm = useConfirm();
  const confirmRollbackAndCreateNew = useConfirm();

  const handleDelete = () => () => {
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun eyða löndun nr: ${props.selectedLanding.id}.`,
      confirmationText: 'Eyða',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'error' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => delLanding())
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  }

  async function delLanding() {
    try {
      await deleteLanding(props.selectedLanding.id);
      props.showSnackbar('Löndun eytt!', 'success');
      props.fetchLandings();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const handleEditCompletedLanding = () => {
    toggleAdminLandingModalOpen();
  }

  const handleRollbackLanding = () => {
    rollbackConfirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun bakfæra löndun nr: ${props.selectedLanding.id}.`,
      confirmationText: 'Bakfæra',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'warning' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => handleRollbackAndCreateNew())
      .catch(() => null);
  };

  const handleRollbackAndCreateNew = () => {
    confirmRollbackAndCreateNew({
      title: 'Löndun mun vera bakfærð',
      description: `Villtu stofna nýja?`,
      confirmationText: 'Já',
      cancellationText: 'Nei',
      confirmationButtonProps: { variant: 'contained' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => rollbackAndCreateNew())
      .catch(() => rollbackLanding());
  };

  async function rollbackAndCreateNew() {
    try {
      const changeClosed: LandingSubmit = {
        revokeAndCreateNew: true
      };
      await patchLanding(props.selectedLanding.id, changeClosed);
      props.showSnackbar('Löndun bakfærð og ný stofnuð!', 'success');
      props.fetchLandings();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  async function rollbackLanding() {
    try {
      const rollback: LandingSubmit = {
        revoke: true
      };
      await patchLanding(props.selectedLanding.id, rollback);
      props.showSnackbar('Löndun bakfærð!', 'success');
      props.setSelectedLanding(props.selectedLanding, props.selectedLanding?.id, props.landingData);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const handleCompleteLanding = () => () => {
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þú ert að loka löndun nr: ${props.selectedLanding.id}.`,
      confirmationText: 'Loka löndun',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'success' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => CompleteLanding())
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  }

  async function CompleteLanding() {
    try {
      const complete: LandingSubmit = {
        isCompleted: true
      };
      await patchLanding(props.selectedLanding.id, complete);
      props.showSnackbar('Löndun lokið!', 'success');
      props.fetchLandings();
      // refetch complete landings as the landing is now completed and should be in that list.
      const shouldRefetch = props.selectedLanding.weightNotes?.some(
        weightNote => weightNote.weightNoteType.id === WeightNoteTypes.TO_REWEIGHING || weightNote.weightNoteType.id === WeightNoteTypes.TO_HOME_REWEIGHING
      );
      props.fetchCompleteLandings(FIRST_PAGE, shouldRefetch, props.selectedLanding.id);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  function getLandingStatusChip(statusId: number | undefined) {
    switch (statusId) {
      case LandingStatuses.OPEN:
        return <>
          <Button
            disableRipple
            component="span"
            variant="contained"
            size={props.isMobileView ? 'large' : 'medium'}
            startIcon={<NewReleasesIcon />}
            disableElevation
            color="primary"
            sx={{ textTransform: 'none', borderRadius: 1, cursor: 'default', width: '100%' }}
          >
            Opin löndun
          </Button>
        </>
      case LandingStatuses.CLOSED:
        return <>
          <Button
            disableRipple
            component="span"
            variant="contained"
            size={props.isMobileView ? 'large' : 'medium'}
            startIcon={<CheckIcon />}
            disableElevation
            color="success"
            sx={{ textTransform: 'none', borderRadius: 1, cursor: 'default', width: '100%' }}
          >
            Lokuð löndun
          </Button>
        </>
      case LandingStatuses.CORRECTED:
        return <>
          <Button
            disableRipple
            component="span"
            variant="contained"
            size={props.isMobileView ? 'large' : 'medium'}
            startIcon={<WarningIcon />}
            disableElevation
            color="warning"
            sx={{ textTransform: 'none', borderRadius: 1, cursor: 'default', width: '100%' }}
          >
            Leiðrétting
          </Button>
        </>
      case LandingStatuses.NEEDS_REWEIGHTING:
        return <>
          <Button
            disableRipple
            component="span"
            variant="contained"
            size={props.isMobileView ? 'large' : 'medium'}
            startIcon={<ErrorIcon />}
            disableElevation
            color="error"
            sx={{ textTransform: 'none', borderRadius: 1, cursor: 'default', width: '100%' }}
          >
            Vantar endurvigtun
          </Button>
        </>
      default:
        return null;
    }
  }

  const handleViewCorrectedLanding = async () => {
    if (props.selectedLanding.correctedLandingId) {
      const correctedLanding = props.landingData.find(landing => landing.id === props.selectedLanding.correctedLandingId);
      if (correctedLanding) {
        props.setSelectedLanding(correctedLanding, correctedLanding.id, props.landingData, correctedLanding.weightNotes[0].id);
      }
      else {
        const landing = await getLandingById(props.selectedLanding.correctedLandingId);
        props.setSelectedLanding(landing, landing.id, props.landingData);
      }
    }
  }

  const isAnyButtonVisible = (
    props.selectedLanding.traceID ||
    (
      props.selectedLanding.landingStatus.id !== LandingStatuses.CLOSED &&
      props.selectedLanding.landingStatus.id !== LandingStatuses.NEEDS_REWEIGHTING &&
      props.user?.role.id !== Number(process.env.REACT_APP_ROLE_READ_ID) &&
      props.selectedLanding.weightNotes?.length !== 0 &&
      props.selectedLanding.weightNotes?.every(weightNote => weightNote.isClosed)
    ) ||
    (
      props.selectedLanding.landingStatus.id !== LandingStatuses.CLOSED &&
      props.selectedLanding.landingStatus.id !== LandingStatuses.NEEDS_REWEIGHTING &&
      !props.selectedLanding.weightNotes?.some(weightNote => weightNote.isClosed) &&
      !props.loading
    ) ||
    (
      props.selectedLanding.landingStatus.id === LandingStatuses.CLOSED &&
      props.user?.role.id === Number(process.env.REACT_APP_ROLE_ADMIN_ID) &&
      !props.selectedLanding.correctedLandingId
    )
  );

  const landingActionButtons = (
    <span style={{ display: 'flex', gap: '0.5em', justifyContent: props.isMobileView ? 'center' : 'space-between', flexDirection: props.isMobileView ? 'column' : 'row', alignItems: 'center', padding: props.isMobileView ? '0.5em' : '' }}>
      {props.selectedLanding.traceID && (
        <Button
          aria-label="compare-catch"
          variant='outlined'
          color='secondary'
          size='small'
          onClick={toggleCompareCatchModalOpen}
          sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 1, minWidth: props.isMobileView ? '13em' : '7em' }}
          disabled={false}
        >
          <CompareIcon fontSize='small' />
          Bera saman afla
        </Button>
      )}
      {props.selectedLanding.landingStatus.id !== LandingStatuses.CLOSED &&
        props.selectedLanding.landingStatus.id !== LandingStatuses.NEEDS_REWEIGHTING &&
        props.user?.role.id !== Number(process.env.REACT_APP_ROLE_READ_ID) &&
        props.selectedLanding.weightNotes?.length !== 0 &&
        props.selectedLanding.weightNotes?.every(weightNote => weightNote.isClosed) &&
        (
          <Button
            aria-label="complete-landing"
            variant='outlined'
            size='small'
            color='success'
            onClick={handleCompleteLanding()}
            sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 1, minWidth: props.isMobileView ? '13em' : '7em' }}
            disabled={
              props.selectedLanding.landingStatus.id === LandingStatuses.CLOSED ||
              props.selectedLanding.landingStatus.id === LandingStatuses.NEEDS_REWEIGHTING ||
              props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)
            }
          >
            <TaskIcon fontSize='small' />
            Ljúka
          </Button>
        )}
      {props.selectedLanding.landingStatus.id !== LandingStatuses.CLOSED &&
        props.selectedLanding.landingStatus.id !== LandingStatuses.NEEDS_REWEIGHTING &&
        !props.selectedLanding.weightNotes?.some(weightNote => weightNote.isClosed) &&
        !props.loading &&
        (
          <Button
            aria-label="delete-landing"
            color='error'
            size='small'
            variant='outlined'
            onClick={handleDelete()}
            sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 1, minWidth: props.isMobileView ? '13em' : '7em' }}
            disabled={
              props.selectedLanding.landingStatus.id === LandingStatuses.CLOSED ||
              props.selectedLanding.landingStatus.id === LandingStatuses.NEEDS_REWEIGHTING ||
              props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)
            }
          >
            <DeleteForeverIcon fontSize='small' />
            Eyða
          </Button>
        )}

      {props.selectedLanding.landingStatus.id === LandingStatuses.CLOSED &&
        props.user?.role.id === Number(process.env.REACT_APP_ROLE_ADMIN_ID) &&
        !props.selectedLanding.correctedLandingId &&
        (
          <>
            <Tooltip title="Bakfæra löndun" arrow>
              <Button
                aria-label="rollback-landing"
                variant='outlined'
                size='small'
                color='warning'
                onClick={handleRollbackLanding}
                sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 1, minWidth: props.isMobileView ? '13em' : '7em' }}
                disabled={props.selectedLanding.landingStatus.id !== LandingStatuses.CLOSED || props.user?.role.id !== Number(process.env.REACT_APP_ROLE_ADMIN_ID)}
              >
                <UndoIcon fontSize='small' />
                Bakfæra
              </Button>
            </Tooltip>

            <Button
              aria-label="edit-landing"
              variant='outlined'
              size='small'
              color='error'
              onClick={handleEditCompletedLanding}
              sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 1, minWidth: props.isMobileView ? '13em' : '7em' }}
              disabled={props.selectedLanding.landingStatus.id !== LandingStatuses.CLOSED || props.user?.role.id !== Number(process.env.REACT_APP_ROLE_ADMIN_ID)}
            >
              <SwapHorizIcon fontSize='small' />
              Breyta
            </Button>
          </>
        )}
    </span>
  );

  return (
    <SplitContainer
      data-testid="landing-info"
      style={{ padding: props.isMobileView ? '0.5em' : '1em' }}
    >
      {(props.selectedLanding.id) && (
        <LandingInfoWrapper>
          <span style={{ display: 'flex', flexDirection: 'row', gap: '0.5em', justifyContent: 'space-between' }}>
            <LandingTitleContainer>
              <LandingTitleFont
                style={{
                  cursor: props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                    props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? 'pointer' : 'default',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                onClick={props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                  props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? () => toggleEditLandingModalOpen('ship') : undefined}
              >
                <span style={{ display: 'flex', flexDirection: 'row', gap: '0.5em', alignItems: 'center' }}>
                  <Tooltip title="Skipaskrárnúmer" arrow enterDelay={200}>
                    <Avatar sx={{ bgcolor: theme.palette.mode === 'dark' ? theme.palette.common.white : '#000', fontSize: 15, fontWeight: '600', height: '40px' }} variant="rounded">
                      {props.selectedLanding.ship.shipRegistrationNumber}
                    </Avatar>
                  </Tooltip>
                  <Tooltip title={<span style={{ whiteSpace: 'pre-line', fontSize: '1.1em' }}>{'Seljandi\n\n' + props.selectedLanding.ship.sellerName + "\n" + props.selectedLanding.ship.sellerSSN + "\n" + props.selectedLanding.ship.sellerAddress + "\n" + props.selectedLanding.ship.sellerPlace}</span>} arrow enterDelay={200}>
                    <span style={{ display: 'flex', flexDirection: 'column', gap: '0em' }}>
                      {props.selectedLanding.ship.name}
                      <span
                        style={{ fontSize: '0.55em', fontWeight: '400', color: theme.palette.text.secondary, lineHeight: '1' }}
                      >
                        {props.selectedLanding.ship.sellerName + " "} ▪&nbsp; Kt.{`${props.selectedLanding.ship.sellerSSN?.slice(0, 6)}-${props.selectedLanding.ship.sellerSSN?.slice(6)}` + " "}
                      </span>
                    </span>
                  </Tooltip>
                </span>

                {props.selectedLanding.correctedLandingId && (
                  <Tooltip title="Skoða upprunalega löndun" arrow enterDelay={200}>
                    <IconButton size="small" onClick={handleViewCorrectedLanding}>
                      <YoutubeSearchedForIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </LandingTitleFont>
            </LandingTitleContainer>

            {(props.isMobileView && isAnyButtonVisible) && (
              <>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  sx={{ '& .MuiMenu-list': { padding: 0 } }}
                >
                  {landingActionButtons}
                </Menu>
              </>
            )
            }

            {/* Show buttons here if in desktop view */}
            {!props.isMobileView && landingActionButtons}
          </span>

          {/* Add a divider in mobile view */}
          {props.isMobileView && <Divider style={{ width: '100%', margin: '0.5em 0' }} />}

          <Tabs
            variant="scrollable"
            scrollButtons={props.isMobileView ? true : false}
            allowScrollButtonsMobile
            value={0}
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
              '& .MuiTabs-scroller': {
                '& .MuiTabs-flexContainer': {
                  gap: '0.5em',
                },
              },
              '& .MuiTabs-indicator': {
                backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
              },
            }}
          >
            <Tab disableRipple style={{ padding: 0, minWidth: 'fit-content' }}
              label={
                <Tooltip title="Staða löndunar" arrow enterDelay={200}>
                  <span style={{ display: 'flex', alignItems: 'center', minWidth: props.isMobileView ? 'fit-content' : '' }}>
                    {getLandingStatusChip(props.selectedLanding.landingStatus.id)}
                  </span>
                </Tooltip>
              } />

            <Tab style={{ padding: 0, minWidth: 'fit-content' }}
              label={
                <Tooltip title="Tegund löndunar" arrow enterDelay={200}>
                  <Button
                    component="span"
                    variant="outlined"
                    size={props.isMobileView ? 'large' : 'medium'}
                    startIcon={<ArticleIcon />}
                    disableElevation
                    color="primary"
                    sx={{
                      textTransform: 'none', borderRadius: 10,
                      cursor: props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                        props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? 'pointer' : 'default',
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                      borderColor: 'lightgray',
                      minWidth: 'fit-content',
                      '&:hover': {
                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                        borderColor: 'darkgray',
                        backgroundColor: '#f8f8f8',
                      },
                    }}
                    onClick={props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                      props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? () => toggleEditLandingModalOpen('landingType') : undefined}
                  >
                    {props.selectedLanding.landingType.name}
                  </Button>
                </Tooltip>
              } />

            <Tab style={{ padding: 0, minWidth: 'fit-content' }}
              label={
                <Tooltip title="Löndun hefst" arrow enterDelay={200}>
                  <Button
                    component="span"
                    variant="outlined"
                    size={props.isMobileView ? 'large' : 'medium'}
                    startIcon={<DateRangeIcon />}
                    disableElevation
                    color="primary"
                    sx={{
                      textTransform: 'none', borderRadius: 10,
                      cursor: props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                        props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? 'pointer' : 'default',
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                      borderColor: 'lightgray',
                      minWidth: 'fit-content',
                      '&:hover': {
                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                        borderColor: 'darkgray',
                        backgroundColor: '#f8f8f8',
                      },
                    }}
                    onClick={props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                      props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? () => toggleEditLandingModalOpen('landingStarts') : undefined}              >
                    {dayjs(props.selectedLanding.landingStarts).format('DD.MM.YYYY')}
                  </Button>
                </Tooltip>
              } />

            {props.selectedLanding.huntingTrip &&
              <Tab style={{ padding: 0, minWidth: 'fit-content' }}
                label={
                  <Tooltip title="Veiðiferð / rekjanleikanúmer" arrow enterDelay={200}>
                    <Button
                      component="span"
                      variant="outlined"
                      size={props.isMobileView ? 'large' : 'medium'}
                      startIcon={<ContentPasteSearchIcon />}
                      disableElevation
                      color="primary"
                      sx={{
                        textTransform: 'none', borderRadius: 10,
                        cursor: props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                          props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? 'pointer' : 'default',
                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                        borderColor: 'lightgray',
                        minWidth: 'fit-content',
                        '&:hover': {
                          color: theme.palette.mode === 'dark' ? 'white' : 'black',
                          borderColor: 'darkgray',
                          backgroundColor: '#f8f8f8',
                        },
                      }}
                      onClick={props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                        props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? () => toggleEditLandingModalOpen('huntingTrip') : undefined}
                    >
                      {props.selectedLanding.huntingTrip}
                    </Button>
                  </Tooltip>
                } />
            }

            <Tab style={{ padding: 0, minWidth: 'fit-content' }}
              label={
                <Tooltip title="Löndun nr." arrow enterDelay={200}>
                  <Button
                    component="span"
                    variant="outlined"
                    size={props.isMobileView ? 'large' : 'medium'}
                    startIcon={<TagIcon />}
                    disableElevation
                    color="primary"
                    sx={{
                      textTransform: 'none', borderRadius: 10,
                      cursor: props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                        props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? 'pointer' : 'default',
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                      borderColor: 'lightgray',
                      minWidth: 'fit-content',
                      '&:hover': {
                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                        borderColor: 'darkgray',
                        backgroundColor: '#f8f8f8',
                      },
                    }}
                    onClick={props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                      props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? () => toggleEditLandingModalOpen('landingNumber') : undefined}
                  >
                    {props.selectedLanding.id}
                  </Button>
                </Tooltip>
              } />

            {props.selectedLanding.comment &&
              <Tab style={{ padding: 0, minWidth: 'fit-content' }}
                label={
                  <Tooltip title="Athugasemd" arrow enterDelay={200}>
                    <Button
                      component="span"
                      variant="outlined"
                      size={props.isMobileView ? 'large' : 'medium'}
                      startIcon={<CommentIcon />}
                      disableElevation
                      color="primary"
                      sx={{
                        textTransform: 'none', borderRadius: 10,
                        cursor: props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                          props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? 'pointer' : 'default',
                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                        borderColor: 'lightgray',
                        minWidth: 'fit-content',
                        '&:hover': {
                          color: theme.palette.mode === 'dark' ? 'white' : 'black',
                          borderColor: 'darkgray',
                          backgroundColor: '#f8f8f8',
                        },
                      }}
                      onClick={props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                        props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? () => toggleEditLandingModalOpen('comment') : undefined}
                    >
                      {props.selectedLanding.comment}
                    </Button>
                  </Tooltip>
                } />
            }

            {(props.selectedLanding.landingStatus.id === LandingStatuses.OPEN || props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED) && (
              <Tab style={{ padding: 0, minWidth: 'fit-content' }}
                label={
                  <Tooltip title="Breyta löndun" arrow enterDelay={200}>
                    <Button
                      component="span"
                      size={props.isMobileView ? 'large' : 'medium'}
                      sx={{
                        textTransform: 'none', borderRadius: 1,
                        cursor: props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                          props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? 'pointer' : 'default',
                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                        borderColor: 'lightgray',
                        minWidth: 'fit-content',
                        '&:hover': {
                          color: theme.palette.mode === 'dark' ? 'white' : 'black',
                          borderColor: 'darkgray',
                          backgroundColor: '#f8f8f8',
                        },
                      }}
                      onClick={props.selectedLanding.landingStatus.id === LandingStatuses.OPEN ||
                        props.selectedLanding.landingStatus.id === LandingStatuses.CORRECTED ? () => toggleEditLandingModalOpen() : undefined}
                    >
                      <EditIcon fontSize='small' />
                    </Button>
                  </Tooltip>
                } />
            )}
          </Tabs>
        </LandingInfoWrapper >
      )
      }

      <EditLandingModal
        editLandingModalOpen={editLandingModalOpen}
        toggleEditLandingModalOpen={toggleEditLandingModalOpen}
        inputToFocus={inputToFocus}
        selectedLanding={props.selectedLanding}
        selectedWeightNote={props.selectedWeightNote}
        landingTypes={props.landingTypes}
        landings={props.landingData}
        fetchLandings={props.fetchLandings}
        showSnackbar={props.showSnackbar}
        user={props.user}
      />
      <AdminLandingModal
        adminLandingModalOpen={adminLandingModalOpen}
        toggleAdminLandingModalOpen={toggleAdminLandingModalOpen}
        selectedLanding={props.selectedLanding}
        selectedWeightNote={props.selectedWeightNote}
        landingTypes={props.landingTypes}
        fetchLandings={props.fetchLandings}
        showSnackbar={props.showSnackbar}
        user={props.user}
        harbours={props.harbours}
      />
      <NewWeightNoteModal
        newWeightNoteModalOpen={props.newWeightNoteModalOpen}
        toggleNewWeightNoteModalOpen={props.toggleNewWeightNoteModalOpen}
        selectedLanding={props.selectedLanding}
        user={props.user}
        fetchLandings={props.fetchLandings}
        showSnackbar={props.showSnackbar}
        fishingGears={props.fishingGears}
        weightNoteTypes={props.weightNoteTypes}
        permits={props.permits}
      />
      <CompareCatchModal
        compareCatchModalOpen={compareCatchModalOpen}
        toggleCompareCatchModalOpen={toggleCompareCatchModalOpen}
        user={props.user}
        traceId={props.selectedLanding.traceID}
      />
    </SplitContainer >
  );
}

export default LandingInfo;
