import { FC, useEffect, useRef, useState } from "react";
import { Autocomplete, Dialog, FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup, TextField, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import DeductionType from "../../../../../Models/CatchRegistrationModels/DeductionType";
import WeightNote from "../../../../../Models/WeightNoteModels/WeightNote";
import { patchDeduction, patchWeight, postDeduction } from "../../../../../Services/LondunarkerfiAPIService";
import CatchRegistration from "../../../../../Models/CatchRegistrationModels/CatchRegistration";
import User from "../../../../../Models/UserModels/User";
import PostDeductionBody from "../../../../../Models/CatchRegistrationModels/PostDeductionBody";
import CatchDeduction from "../../../../../Models/CatchRegistrationModels/CatchDeduction";
import WeightNoteTypes from "../../../../../Constants/WeightNoteTypes";
import CatchRegistrationDefaults from "../../../../../Constants/CatchRegistrationDefaults";
import { MobilePaperComponent, PaperComponent } from "../../../../../SharedComponents/Paper/CustomPaper";
import Vehicle from "../../../../../Models/CatchRegistrationModels/Vehicle";
import PostCatchBody from "../../../../../Models/CatchRegistrationModels/PostCatchBody";
import { logError } from "../../../../../Helpers/LogError";

interface CatchRegistrationDeductionModalProps {
  open: boolean;
  toggleOpen: () => void;
  deductionTypes: DeductionType[];
  selectedWeightNote: WeightNote;
  weightItem: CatchRegistration | undefined;
  deductionId: number | undefined;
  user: User;
  resetDeductionItem: () => void;
  setNewWeightItem: (weightId: number, formData?: PostCatchBody) => Promise<void>;
  setTempDeductions: (deductions: CatchDeduction[]) => void;
  tempDeductions: CatchDeduction[];
  showSnackbar: (message: string, severity: 'success' | 'error') => void;
  vehicles: Vehicle[];
  formData: PostCatchBody;
}

/**
 * Functional component for CatchRegistrationDeduction modal
 * @param {CatchRegistrationDeductionModalProps} props
 * @returns {JSX} - renders inpput fields for the deduction registration modal
 * 
 * Responsible for allowing users input in registering or updating deduction.
 */

const CatchRegistrationDeductionModal: FC<CatchRegistrationDeductionModalProps> = (props: CatchRegistrationDeductionModalProps) => {
  const TARA = 'tara';
  const LAST_BRUTTO = 'lastBrutto';
  const NONE_SELECTED = '';
  const [loading, setLoading] = useState<boolean>(false);
  const [shouldRenderDeductionTypes, setShouldRenderDeductionTypes] = useState<boolean>(true);
  const [selectedDeductionType, setSelectedDeductionType] = useState<DeductionType | null>(null);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);
  const [selectedVehicleDeduction, setSelectedVehicleDeduction] = useState<number>(0);
  const [selectedVehicleDeductionType, setSelectedVehicleDeductionType] = useState<string>(NONE_SELECTED);
  const [quantity, setQuantity] = useState<number | undefined>(0);
  const [weight, setWeight] = useState<number | undefined>(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const MAX_WEIGHT_DB_VALUE = 99999.999;
  const MAX_QUANTITY_DB_VALUE = 99999;
  const MAX_TOTAL_DEDUCTION_DB_VALUE = 99999.999;

  const defaultDeductionType = props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID || props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_SAMPLE_ID ?
    props.deductionTypes.find(deductionType => deductionType.id === CatchRegistrationDefaults.PACKAGES_DEDUCTION_TYPE_ID) :
    props.deductionTypes.find(deductionType => deductionType.id === CatchRegistrationDefaults.TUB_OR_BOX_DEDUCTION_TYPE_ID);

  useEffect(() => {
    setSelectedDeductionType(defaultDeductionType || null);
  }, [defaultDeductionType]);

  useEffect(() => {
    if (props.deductionId) {
      const selectedDeduction = props.weightItem?.deductions.find(deduction => deduction.id === props.deductionId);
      const tempDeduction = props.tempDeductions.find(deduction => deduction.id === props.deductionId);
      const registeredVehicle = props.vehicles.find(vehicle => vehicle.id === props.weightItem?.vehicle?.id);
      const tempVehicle = props.vehicles.find(vehicle => vehicle.id === tempDeduction?.vehicleId);
      if (selectedDeduction) {
        setSelectedDeductionType(props.deductionTypes.find(deductionType => deductionType.id === selectedDeduction.deductionTypeId) || null);
        setQuantity(selectedDeduction.unit);
        setWeight(selectedDeduction.weight);
        setTotalDeduction(selectedDeduction.quantity);
      } else {
        if (tempDeduction) {
          setSelectedDeductionType(props.deductionTypes.find(deductionType => deductionType.id === tempDeduction.deductionTypeId) || null);
          setQuantity(tempDeduction.unit);
          setWeight(tempDeduction.weight);
          setTotalDeduction(tempDeduction.quantity);
        }
      }
      if (registeredVehicle) {
        setSelectedDeductionType(props.deductionTypes.find(deductionType => deductionType.id === CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID) || null);
        setSelectedVehicle(registeredVehicle);
        setSelectedVehicleDeduction(props.weightItem?.carDeduction === registeredVehicle.vehicleTare ? registeredVehicle?.vehicleTare : registeredVehicle?.vehicleGross);
        setSelectedVehicleDeductionType(props.weightItem?.carDeduction === registeredVehicle.vehicleTare ? TARA : LAST_BRUTTO);
        setShouldRenderDeductionTypes(false);
      } else {
        if (tempVehicle) {
          setSelectedDeductionType(props.deductionTypes.find(deductionType => deductionType.id === CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID) || null);
          setSelectedVehicle(tempVehicle);
          setSelectedVehicleDeduction(tempDeduction?.weight === tempVehicle.vehicleTare ? tempVehicle.vehicleTare : tempVehicle.vehicleGross);
          setSelectedVehicleDeductionType(tempDeduction?.weight === tempVehicle.vehicleTare ? TARA : LAST_BRUTTO);
          setShouldRenderDeductionTypes(false);
        }
      }
    }
  }, [props.deductionId, props.deductionTypes, props.tempDeductions, props.vehicles, props.weightItem?.carDeduction, props.weightItem?.deductions, props.weightItem?.vehicle?.id]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (submitButtonRef.current) {
          submitButtonRef.current.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleDeductionTypeChange = (newValue: DeductionType | null) => {
    setSelectedDeductionType(newValue);
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(event.target.value);
    // handle if a multiple between quantity and weight exceeds the db value for total deduction
    if (inputValue * (weight || 1) > MAX_TOTAL_DEDUCTION_DB_VALUE) {
      return;
    }
    if (
      inputValue < 0 ||
      inputValue > MAX_QUANTITY_DB_VALUE ||
      (
        props.weightItem && (
          (props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID && inputValue > (props.weightItem?.sampleWeight ?? 0)) ||
          (props.selectedWeightNote?.weightNoteType?.id !== WeightNoteTypes.PRODUCT_ID && inputValue > (props.weightItem?.quantity ?? 0)))
      )
    ) {
      return;
    }
    const newQuantity = isNaN(inputValue) ? 0 : inputValue;
    const newTotal = weight ? weight * newQuantity : 0;
    setQuantity(newQuantity);
    setTotalDeduction(newTotal);
  };

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseFloat(event.target.value);
    // handle if a multiple between quantity and weight exceeds the db value for total deduction
    if (inputValue * (quantity || 1) > MAX_TOTAL_DEDUCTION_DB_VALUE) {
      return;
    }
    if (
      inputValue < 0 ||
      inputValue > MAX_WEIGHT_DB_VALUE ||
      (
        props.weightItem && (
          (props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID && inputValue > (props.weightItem?.sampleWeight ?? 0)) ||
          (props.selectedWeightNote?.weightNoteType?.id !== WeightNoteTypes.PRODUCT_ID && inputValue > (props.weightItem?.quantity ?? 0)))
      )
    ) {
      return;
    }
    const newWeight = isNaN(inputValue) ? 0 : inputValue;
    const newTotal = quantity ? quantity * newWeight : 0;
    setWeight(newWeight);
    setTotalDeduction(newTotal);
  };

  const handleVehicleChange = (event: React.ChangeEvent<object>, value: Vehicle | null) => {
    if (value) {
      setSelectedVehicle(value);
      setSelectedVehicleDeduction(value.vehicleTare);
      setSelectedVehicleDeductionType(TARA);
    } else {
      setSelectedVehicle(null);
      setSelectedVehicleDeduction(0);
      setSelectedVehicleDeductionType(NONE_SELECTED);
    }
  };

  const handleVehicleDeductionChange = (value: string) => {
    const weight = value === TARA ? selectedVehicle?.vehicleTare : selectedVehicle?.vehicleGross;
    setSelectedVehicleDeduction(weight || 0);
    setSelectedVehicleDeductionType(value);
  };

  async function handleDeductionAdd(): Promise<void> {
    try {
      setLoading(true);
      if (props.weightItem) {
        // weightItem has been registered. Vehicle is a special case since it is registered on the weightItem, not as a deduction in db.
        if (selectedVehicle && selectedVehicleDeductionType) {
          const updateWeight: PostCatchBody = {
            vehicleId: selectedVehicle?.id,
            vehicleDeduction: selectedVehicleDeduction,
            shouldClearVehicle: false
          };
          await patchWeight(props.weightItem.id, updateWeight);
          await props.setNewWeightItem(props.weightItem.id, props.formData);
          props.showSnackbar('Frádráttur flutninsgtækis skráður!', 'success');
          handleClose();
        } else {
          const newDeductionItem: PostDeductionBody = {
            harbourId: props.user?.userPickedHarbour || 0,
            typeId: selectedDeductionType?.id || 0,
            unit: quantity ? quantity : 0,
            quantity: weight ? weight : 0
          };
          const status = await postDeduction(newDeductionItem, props.weightItem.id);
          if (status) {
            await props.setNewWeightItem(props.weightItem.id, props.formData);
            props.showSnackbar('Frádráttur skráður!', 'success');
          }
        }

      } else {
        // weightItem has not been registered, so we add it as a tempdeduction
        if (selectedVehicle && selectedVehicleDeductionType) {
          const newTempDeductionItem: CatchDeduction = {
            id: props.tempDeductions.length + 1,
            weightId: 0,
            deductionTypeId: CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID,
            deductionType: `Flutningstæki (${selectedVehicle?.vehicleRegistrationNumber})`,
            weight: selectedVehicleDeduction,
            unit: 1,
            quantity: selectedVehicleDeduction,
            shouldWithdraw: true,
            vehicleId: selectedVehicle?.id
          };
          props.setTempDeductions([...props.tempDeductions, newTempDeductionItem]);
        } else {
          const newTempDeductionItem: CatchDeduction = {
            id: props.tempDeductions.length + 1,
            weightId: 0,
            deductionTypeId: selectedDeductionType?.id || 0,
            deductionType: selectedDeductionType?.name || "",
            weight: weight || 0,
            unit: quantity || 0,
            quantity: totalDeduction,
            shouldWithdraw: true
          };
          props.setTempDeductions([...props.tempDeductions, newTempDeductionItem]);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
    setLoading(false);
    handleClose();
  }

  const handleDeductionUpdate = async () => {
    try {
      setLoading(true);
      if (props.weightItem && props.deductionId) {
        // weightItem has been registered. Vehicle is a special case since it is registered on the weightItem, not as a deduction in db.
        if (selectedVehicle && selectedVehicleDeductionType) {
          const updateWeight: PostCatchBody = {
            vehicleId: selectedVehicle?.id,
            vehicleDeduction: selectedVehicleDeduction,
            shouldClearVehicle: false
          };
          await patchWeight(props.weightItem.id, updateWeight);
          await props.setNewWeightItem(props.weightItem.id, props.formData);
          props.showSnackbar('Frádráttur flutninsgtækis uppfærður!', 'success');
          handleClose();
        } else {
          const updatedDeductionItem: PostDeductionBody = {
            harbourId: props.user?.userPickedHarbour || 0,
            typeId: selectedDeductionType?.id || 0,
            unit: quantity ? quantity : 0,
            quantity: weight ? weight : 0
          };
          const status = await patchDeduction(props.weightItem.id, props.deductionId, updatedDeductionItem);
          if (status) {
            await props.setNewWeightItem(props.weightItem.id, props.formData);
            props.showSnackbar('Frádráttur uppfærður!', 'success');
          }
        }
      } else {
        // weightItem has not been registered, so we add it as a tempdeduction
        if (selectedVehicle && selectedVehicleDeductionType) {
          const updatedTempDeductionItem: CatchDeduction = {
            id: props.deductionId || 0,
            weightId: 0,
            deductionTypeId: CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID,
            deductionType: `Flutningstæki (${selectedVehicle?.vehicleRegistrationNumber})`,
            weight: selectedVehicleDeduction,
            unit: 1,
            quantity: selectedVehicleDeduction,
            shouldWithdraw: true,
            vehicleId: selectedVehicle?.id
          };
          const tempDeductions = props.tempDeductions.map(deduction => deduction.id === props.deductionId ? updatedTempDeductionItem : deduction);
          props.setTempDeductions(tempDeductions);
        } else {
          const updatedTempDeductionItem: CatchDeduction = {
            id: props.deductionId || 0,
            weightId: 0,
            deductionTypeId: selectedDeductionType?.id || 0,
            deductionType: selectedDeductionType?.name || "",
            weight: weight || 0,
            unit: quantity || 0,
            quantity: totalDeduction,
            shouldWithdraw: true
          };
          const tempDeductions = props.tempDeductions.map(deduction => deduction.id === props.deductionId ? updatedTempDeductionItem : deduction);
          props.setTempDeductions(tempDeductions);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
    setLoading(false);
    handleClose();
  }

  const handleClose = () => {
    setWeight(0);
    setQuantity(0);
    setTotalDeduction(0);
    setSelectedDeductionType(null);
    setSelectedVehicle(null);
    setSelectedVehicleDeduction(0);
    setSelectedVehicleDeductionType(NONE_SELECTED);
    setShouldRenderDeductionTypes(true);
    setSelectedDeductionType(defaultDeductionType || null);
    props.resetDeductionItem();
    props.toggleOpen();
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <div id="draggable-dialog-title" style={{ fontSize: '1.5em', fontWeight: 'bold', lineHeight: '1.5em', margin: '0', cursor: 'move' }}>
          {props.deductionId ? 'Breyta frádrætti' : 'Nýr frádráttur'}
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </div>
        {shouldRenderDeductionTypes &&
          <div>
            <Autocomplete
              disablePortal
              options={
                props.weightItem?.vehicle?.id ||
                  props.tempDeductions.find(deduction => deduction.deductionTypeId === CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID) ||
                  props.deductionId ?
                  props.deductionTypes.filter(deductionType => deductionType.id !== CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID) :
                  props.deductionTypes
              }
              getOptionLabel={(option) => option.name || ""}
              value={selectedDeductionType ? selectedDeductionType : null}
              isOptionEqualToValue={(option, value) => value.id ? option?.id === value?.id : true}
              renderInput={(params) => <TextField label="Tegund frádráttar" {...params} InputProps={{ ...params.InputProps }} />}
              onChange={(event, value) => handleDeductionTypeChange(value)}
              disabled={props.selectedWeightNote.isClosed || loading}
              sx={{ width: '100%' }}
              slotProps={{
                popper: {
                  disablePortal: false,
                }
              }}
            />
          </div>
        }

        {selectedDeductionType?.id !== CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID
          &&
          <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', justifyContent: 'space-between', gap: '1em' }}>
            <TextField
              fullWidth
              type="number"
              label="Fjöldi"
              variant="outlined"
              onChange={handleQuantityChange}
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                  e.preventDefault()
                }
              }}
              disabled={props.selectedWeightNote.isClosed || loading}
              value={quantity ? quantity : ""}
              autoFocus
              required
              inputProps={{
                min: 0,
                max: props.weightItem ?
                  (props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID ? props.weightItem?.sampleWeight ?? 0 : props.weightItem?.quantity ?? 0) :
                  MAX_QUANTITY_DB_VALUE
              }}
            />
            <TextField
              fullWidth
              type="number"
              label="Þyngd"
              variant="outlined"
              onChange={handleWeightChange}
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                  e.preventDefault()
                }
              }}
              disabled={props.selectedWeightNote.isClosed || loading}
              value={weight ? weight : ""}
              required
              inputProps={{
                min: 0,
                max: props.weightItem ?
                  (props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID ? props.weightItem?.sampleWeight ?? 0 : props.weightItem?.quantity ?? 0) :
                  MAX_WEIGHT_DB_VALUE
              }}
            />
            <TextField
              fullWidth
              type="number"
              disabled={true}
              label="Magn"
              variant="filled"
              value={totalDeduction.toFixed(2)}
            />
          </div>
        }

        {selectedDeductionType?.id === CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID
          &&
          <div>
            <Autocomplete
              sx={{ width: '100%' }}
              disablePortal
              autoHighlight
              autoSelect
              disabled={props.selectedWeightNote.isClosed}
              options={props.vehicles}
              value={selectedVehicle || null}
              getOptionLabel={(option) => option.vehicleNumber || ""}
              isOptionEqualToValue={(option, value) => value.id ? option.id === value.id : true}
              onChange={handleVehicleChange}
              slotProps={{
                popper: {
                  disablePortal: false,
                }
              }}
              renderInput={(params) =>
                <TextField
                  label="Velja flutningstæki"
                  color="primary"
                  {...params}
                  error={props.weightItem && props.weightItem.carDeduction != selectedVehicleDeduction && props.weightItem?.quantity < selectedVehicleDeduction}
                  helperText={
                    (props.weightItem && props.weightItem.carDeduction != selectedVehicleDeduction && props.weightItem?.quantity < selectedVehicleDeduction && 'Þyngd flutningstækis er meiri en skráð magn')
                  }
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalShippingIcon color='primary' />
                      </InputAdornment>
                    )
                  }}
                />}
            />

            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <RadioGroup
                row
                aria-label="Vehicle Deduction"
                name="vehicleDeduction"
                onChange={(event) => handleVehicleDeductionChange(event.target.value)}
                value={selectedVehicleDeductionType}
              >
                <FormControlLabel
                  value={TARA}
                  control={<Radio />}
                  label="Tara"
                  disabled={!selectedVehicle?.id}
                />
                <FormControlLabel
                  value={LAST_BRUTTO}
                  control={<Radio />}
                  label="Síðasta brúttó"
                  disabled={!selectedVehicle?.id}
                />
              </RadioGroup>

              <p style={{ color: 'red', fontSize: '1.2em', margin: 0 }}>
                <>{selectedVehicleDeduction != 0 ? (selectedVehicleDeduction / 1000).toFixed(3) : '0'} kg</>
              </p>

            </div>
          </div>
        }

        {selectedDeductionType?.id === CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID &&
          <div style={{ width: '100%' }}>
            <LoadingButton
              size="medium"
              fullWidth
              endIcon={props.deductionId ? <SaveIcon /> : <CheckIcon />}
              color={props.deductionId ? 'primary' : 'success'}
              variant="contained"
              onClick={props.deductionId ? handleDeductionUpdate : handleDeductionAdd}
              disabled={
                !selectedVehicle ||
                !selectedVehicleDeductionType ||
                props.selectedWeightNote.isClosed ||
                (props.weightItem && props.weightItem?.quantity < selectedVehicleDeduction) ||
                loading
              }
            >
              <span>{props.deductionId ? 'Uppfæra' : 'Staðfesta'}</span>
            </LoadingButton>
          </div>
        }

        {selectedDeductionType?.id !== CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID &&
          <div style={{ width: '100%' }}>
            <LoadingButton
              size="medium"
              fullWidth
              ref={submitButtonRef}
              loading={loading}
              endIcon={props.deductionId ? <SaveIcon /> : <CheckIcon />}
              color={props.deductionId ? 'primary' : 'success'}
              variant="contained"
              onClick={props.deductionId ? handleDeductionUpdate : handleDeductionAdd}
              disabled={
                props.selectedWeightNote.isClosed ||
                loading ||
                !selectedDeductionType ||
                !quantity ||
                !weight ||
                quantity <= 0 ||
                weight <= 0 ||
                totalDeduction <= 0 ||
                props.weightItem && totalDeduction > (props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID ? props.weightItem?.sampleWeight ?? 0 : props.weightItem?.quantity ?? 0)
              }
            >
              <span>{props.deductionId ? 'Uppfæra' : 'Staðfesta'}</span>
            </LoadingButton>
          </div>
        }
      </Dialog >
    </div >
  );
};

export default CatchRegistrationDeductionModal;